<template>
  <div style class="bg-green l-grow">
    <q-select
      filled
      label="Pilih Camera"
      bg-color="white"
      option-value="deviceId"
      option-label="label"
      :options="listCamera"
      v-model="cam.activeCam"
      @update:model-value="changeActiveCam"
      emit-value
      map-options
    ></q-select>
    <camera
      ref="camera"
      @camera-change="reportCam"
      :resolution="{ width: 320, height: 320 }"
      style="width:320px; height:100%"
      autoplay
    >
      <q-btn
        round
        dense
        @click="snapshot"
        size="lg"
        icon="photo_camera"
        color="white"
        text-color="black"
        style="position: fixed; bottom: 12px; left:50%; right:50%;"
      ></q-btn>
    </camera>
    <q-dialog v-model="showPict">
      <q-card class="column" style="height:100%; width:100%">
        <q-img
          class="col-8"
          :src="capture"
          :ratio="1"
          style="height:80%"
        ></q-img>
        <!-- <q-card-section class="col-4">
          <q-img :src="capture" :ratio="1" style="height:80%"></q-img>
        </q-card-section> -->
        <!-- <q-card-section class="col-4">
          <img :src="capture" />
        </q-card-section> -->
        <q-card-actions class="col-2" align="between">
          <p class="h6 text-center full-width">SIMPAN GAMBAR?</p>
          <q-btn outline color="negative" label="Tidak" v-close-popup></q-btn>
          <q-btn
            unelevated
            color="positive"
            label="Ya"
            @click="savepict(route.params.id)"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref, onMounted, reactive, inject, onUnmounted } from "vue";
import useVisit from "./useVisit";
export default {
  components: {},
  setup() {
    const $http = inject("$http");
    const camera = ref();
    const { data, savepict, route } = useVisit();
    let showPict = ref(false);
    let capture = ref();
    let listCamera = ref();
    let cam = reactive({
      msg: "default",
      activeCam: null,
    });

    const snapshot = async () => {
      const blob = await camera.value?.snapshot();
      data.img = blob;
      capture.value = URL.createObjectURL(data.img);

      showPict.value = true;
    };

    const changeActiveCam = async () => {
      // console.log(cam.activeCam);
      cam.msg = "changing camera...";
      camera.value.changeCamera(cam.activeCam);
    };
    const reportCam = (id) => {
      cam.msg = " cam change to " + id;
    };
    onMounted(async () => {
      listCamera.value = await camera.value?.devices(["videoinput"]);
      cam.activeCam = listCamera.value[0].deviceId;
      await camera.value?.changeCamera(cam.activeCam);
    });

    onUnmounted(() => {
      // camera.stop();
    });

    return {
      camera,
      snapshot,
      changeActiveCam,
      capture,
      listCamera,
      showPict,
      cam,
      data,
      savepict,
      route,
    };
  },
};
</script>
